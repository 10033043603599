import React, { Component } from 'react';
import { compose } from 'redux';
import { translate } from "react-i18next";
import { withStyles } from '@material-ui/core';
import AssetHelper from 'AssetHelper';
import Modal from 'components/modal/index.jsx';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import './index.scss';

const styles = theme => ({
  overlay: {
    background: `url(${AssetHelper.staticPath('/static/home/viewing/background.jpg')})`,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundSize: 'cover',
    zIndex: '1000',
  },
  modalTitle: {
    background: "#2E2E2E",
    padding: "22px",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#fff",
    textAlign: "left",
    marginTop: "-1px",
  }
});

class PrivtePresentationOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
    };
    this.onPasswordInputChange = this.onPasswordInputChange.bind(this);
    this.onPasswordSubmit = this.onPasswordSubmit.bind(this);
  }
  onPasswordInputChange(event) {
    this.setState({
      password: event.target.value,
    });
  }
  onPasswordSubmit(e) {
    e.preventDefault();
    const { fetchPresentation, hash } = this.props;
    const { password } = this.state;
    fetchPresentation({hash: hash, password: password});
  }
  render() {
    const { t, classes, error } = this.props;
    const { password } = this.state;
    const submitButtonDisabled = password.length < 6 ? true : false;
    return (
      <div className={classes.overlay}>
        <Modal open={true} className="privatePresentation-modal">
          <div className="modal-title">{t('privatePresentationModal.title')}</div>
          <div className="modal-content">
            <form onSubmit={this.onPasswordSubmit}>
              <label>{t('privatePresentationModal.label')}</label>
              <input
                type="password"
                value={password}
                name="password"
                onChange={this.onPasswordInputChange}
                placeholder="******" />
              {error ?
                <Typography className="error">{t('privatePresentationModal.error')}</Typography> :
                <Typography className="tip">
                  {t('privatePresentationModal.tip')}
                </Typography>
              }
              <input type="submit" disabled={submitButtonDisabled} value={t('common.next')} className="cta-button" />
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

PrivtePresentationOverlay.propTypes = {
  t: PropTypes.shape({}).isRequired,
  classes: PropTypes.shape({}).isRequired,
  fetchPresentation: PropTypes.func,
  error: PropTypes.string,
  hash: PropTypes.string,
};

PrivtePresentationOverlay.defaultProps = {
  error: false,
  password: '',
  fetchPresentation: false,
  hash: '',
};

export default compose(
  translate('translations'),
  withStyles(styles)
)(PrivtePresentationOverlay);

import React from "react";
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ user, component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    let returnComponent;

    if (!user || user.state === 'loading') {
      returnComponent = <div></div>;
    } else if (user.state === 'loggedIn') {
      returnComponent = <Component {...props} />;
    } else {
      returnComponent = <Redirect to={{
        pathname: '/login',
        state: {from: props.location}
      }}/>;
    }
  
    return returnComponent;
  }} />
);

export default PrivateRoute;

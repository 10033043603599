import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Play from 'static/home/icons/play.svg';
import Config from 'config.js';
import ClassNames from 'classnames';
import './index.scss';
import IconViews from 'static/home/icons/views.svg';
import AssetHelper, { ImageVariants } from 'AssetHelper';

export default class Grid extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { classes } = this.props;
    let listItems = this.props.items.map((item, index) => {
      let hash = item.thumbnail && item.thumbnail.hash,
        imgUrl,
        backgroundStyle,
        avatarUrl;

      if (hash) {
        imgUrl = AssetHelper.imagePath(hash, ImageVariants.TILE);

        backgroundStyle = {
          backgroundImage: 'url(' + imgUrl + ')',
          backgroundPosition: 'center',
          backgroundSize: 'cover'
        };
      }

      if (item.owner.avatar && item.owner.avatar.hash) {
        avatarUrl = AssetHelper.imagePath(item.owner.avatar.hash, ImageVariants.THUMBNAIL);
      } else {
        avatarUrl = AssetHelper.staticPath('/static/home/base-avatar.png');
      }

      listItems = [];
      return (
        <div key={index} className={ClassNames('presentations__presentation-container', classes)}>
          <div className="presentations__presentation">
            <Link to={"/p/" + item.hash + '-' + item.alias} className="presentations__link">
              <div style={backgroundStyle} className="presentations__background">
                <div className="presentations__overlay">
                  <Play className="play" />

                </div>
                <div className="presentations__details">
                  <h4 className="presentations__name">{item.name}</h4>
                </div>
              </div>
            </Link>
            <div className="presentations__info">
              <div className="presentations__author">
                <img src={avatarUrl} alt={item.owner.name + ' avatar'} className="presentations__avatar" />
                <div className="presentations__author-name">{item.owner.name}</div>
              </div>
              <div className="presentations__stats">
                <IconViews />
                <div className="presentations__views">{item.viewCount}</div>
              </div>
            </div>
          </div>
        </div>
      )
    });

    return <div className="presentations">{listItems}</div>;
  }
}

import React, { Component } from 'react';
import { translate } from "react-i18next";
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { WithDependency } from 'shared/DependencyContext';
import Classnames from 'classnames';
import IconArrow from 'static/home/icons/arrow-small.svg';
import IconAdd from '@material-ui/icons/Add';
import IconRemove from '@material-ui/icons/Remove';
import IconPause from 'static/home/presentation/pause-icon.svg';
import IconAutoplay from 'static/home/presentation/autoplay-icon.svg';
import IconVr from 'static/home/presentation/vr-icon.svg';
import IconFullscreen from 'static/home/presentation/fullscreen-icon.svg';
import IconLayers from 'static/home/presentation/layers-icon.svg';
import IconInfo from 'static/home/presentation/info-icon.svg';
import IconGyroscope from 'static/home/presentation/gyroscope.svg';
import IconGyroscopeOff from 'static/home/presentation/gyroscope-off.svg';
import VRModal from 'components/VRModal/';
import Tooltip from '@material-ui/core/Tooltip';
import SpaceplansModal from 'components/spaceplansModal';
import { NavBarStyles } from 'components/navBar';
import AssetHelper, { ImageVariants } from 'AssetHelper';
import Slider from 'rc-slider';
import './index.scss';
import StartCoViewingVRModal from 'components/startCoViewingVRModal';
import StartCoViewingModal from "../startCoViewingModal";

const styles = {
};


function isiOS() {
  return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

class PresentationOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentOpened: false,
      firstChangeScene: true,
      sceneName: null,
      sceneId: null,
      spaceplansOpened: false,
      currentSpaceplan: 0,
      autoplay: false,
      zoom: 1,
      isGyroscopeEnabled: null,
      spaceplansKey: false,
      isiOSDevice: isiOS(),
      viewingVRModalOpen: false,
      vrAviable: null,
      vrChecked: false,
      coviewing: false
    };
    this.prevScene = this.prevScene.bind(this);
    this.nextScene = this.nextScene.bind(this);
    this.handleKeyboard = this.handleKeyboard.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.changeZoom = this.changeZoom.bind(this);
    this.toggleVR = this.toggleVR.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleContent = this.toggleContent.bind(this);
    this.toggleQRModal = this.toggleQRModal.bind(this);
    this.toggleSpaceplansModal = this.toggleSpaceplansModal.bind(this);
    this._onFullscreenChange = this._onFullscreenChange.bind(this);
    this._onChangeScene = this._onChangeScene.bind(this);
    this.setPlanIndex = this.setPlanIndex.bind(this);
    this.toggleAutoplay = this.toggleAutoplay.bind(this);
    this.handleAutoplayStart = this.handleAutoplayStart.bind(this);
    this.handleAutoplayStop = this.handleAutoplayStop.bind(this);
    this.enableGyroscope = this.enableGyroscope.bind(this);
    this.disableGyroscope = this.disableGyroscope.bind(this);
    this.startVRMode = this.startVRMode.bind(this);
    this.toggleSpaceplansKey = this.toggleSpaceplansKey.bind(this);
    this.detectMobile = this.detectMobile.bind(this);
    this.openVRModal = this.openVRModal.bind(this);
    this.isVRAviable = this.isVRAviable.bind(this);
  }

  componentWillMount() {
    const { player, navBar} = this.props;
    player.getInstance().on('changeScene', this._onChangeScene);
    player.getInstance().once('changeScene', (e) => {
      this.setState({
        firstChangeScene: false,
        sceneName: e.sceneName,
      });
    });
    navBar.setStyle(NavBarStyles.minimalPlayer);
    navBar.hidePageLinks();
  }

  componentDidMount() {
    window.addEventListener('keyup', this.handleKeyboard);
    window.addEventListener("fullscreenchange", this._onFullscreenChange);
    window.addEventListener("mozfullscreenchange", this._onFullscreenChange);
    window.addEventListener("webkitfullscreenchange", this._onFullscreenChange);
  }

  detectMobile() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
  componentWillReceiveProps(nextProps) {
    const { player} = this.props;
    if (nextProps.metatags) {
      player.setThumbnailHash(nextProps.metatags.thumbnail.hash);
    }
    player.getInstance().on('autoplay.start', this.handleAutoplayStart);
    player.getInstance().on('autoplay.stop', this.handleAutoplayStop);

    player.isGyroscopeEnabled()
      .then(val => {
        this.setState({ isGyroscopeEnabled: val })
      });

    if (window.location.pathname.includes('/v/') || window.location.pathname.includes('/vh/')) {
      this.setState({ coviewing: true });
    }
    if (this.props.presentation && typeof this.vrAviable !== "boolean") {
      this.isVRAviable();
    }
  }
  componentWillUnmount() {
    const { player, navBar} = this.props;
    player.getInstance().off('changeScene', this._onChangeScene);
    navBar.setCustomLogo();
    navBar.setStyle();
    navBar.showPageLinks();
    navBar.show();
    window.removeEventListener('keyup', this.handleKeyboard);
    window.removeEventListener("fullscreenchange", this._onFullscreenChange);
    window.removeEventListener("mozfullscreenchange", this._onFullscreenChange);
    window.removeEventListener("webkitfullscreenchange", this._onFullscreenChange);
    player.getInstance().off('autoplay.start', this.handleAutoplayStart);
    player.getInstance().off('autoplay.stop', this.handleAutoplayStop);
    player.stopAutoplay();
    // window.jivo_init();

  }
  _onFullscreenChange() {
    this.setState({
      fullscreenActive: !!(document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement),
    }, () => {
      if (this.state.contentOpened) {
        this.toggleContent();
      }
      if (this.state.fullscreenActive) {
        this.props.navBar.hide();
      } else {
        this.props.navBar.show();
      }
    });
  }
  _onChangeScene(e) {
    const { spaceplansOpened } = this.state;
    if (!this.state.firstChangeScene) {
      this.setState({
        sceneName: e.sceneName,
      });
      if(spaceplansOpened) {
        this.toggleSpaceplansModal();
      }
    }
  }
  changeZoom(newZoom) {
    this.props.player.changeZoom(newZoom);
  }
  zoomIn() {
    this.props.player.zoomIn();
  }
  zoomOut() {
    this.props.player.zoomOut();
  }
  enableGyroscope() {
    this.props.player.enableGyroscope()
      .then(() => {
        this.setState({ isGyroscopeEnabled: true });
        this.props.player.stopAutoplay();
      });
  }
  disableGyroscope() {
    this.props.player.disableGyroscope()
      .then(() => this.setState({ isGyroscopeEnabled: false }));
  }
  toggleAutoplay() {
    if (this.state.autoplay) {
      this.props.player.stopAutoplay();
    } else {
      this.props.player.startAutoplay();
      this.state.isGyroscopeEnabled && this.disableGyroscope();
    }
  }
  handleAutoplayStart() {
    this.setState({ autoplay: true });
  }
  handleAutoplayStop() {
    this.setState({ autoplay: false });
  }
  toggleVR() {
    this.props.player.isVRCompatible().then(() => {
      this.setState({ viewingVRModalOpen: false });
      this.props.player.toggleVRMode();
    }, this.toggleQRModal);
  }
  openVRModal() {
    this.props.player.toggleVRMode();
  }
  toggleFullscreen() {
    function requestFullscreen() {
      let elem = document.documentElement;

      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    }

    function exitFullscreen() {
      if(document.exitFullscreen) {
        document.exitFullscreen();
      } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }

    if (this.state.fullscreenActive) {
      exitFullscreen();
    } else {
      requestFullscreen();
    }
  }
  toggleContent() {
    const { spaceplansOpened, contentOpened, fullscreenActive } = this.state;
    if (!fullscreenActive) {
      if (spaceplansOpened && !contentOpened) {
        this.setState({
          spaceplansOpened: false,
        });
      }
      if (this.props.onToggle) {
        this.props.onToggle(!contentOpened);
      }
      this.setState({
        contentOpened: !contentOpened,
      });
    }
  }
  setPlanIndex(planIndex) {
    this.setState({
      currentSpaceplan: planIndex,
    })
  }

  toggleSpaceplansModal() {
    const { contentOpened,spaceplansOpened } = this.state;
    this.setState(state => ({
        ...state,
        spaceplansOpened: !spaceplansOpened,
      }));
      if(contentOpened) {
        this.toggleContent();
      }
  }

  toggleQRModal() {
    this.setState({
      VRModalOpened: !this.state.VRModalOpened,
    });
  }
  handleKeyboard(e) {
    if (e.code === 'ArrowLeft') {
      this.props.player.stopAutoplay();
      this.prevScene();
    }
    if (e.code === 'ArrowRight') {
      this.props.player.stopAutoplay();
      this.nextScene();
    }
  }
  prevScene() {
    const { player, changeSceneWithArrowsEnabled } = this.props;
    if (player && changeSceneWithArrowsEnabled) {
      player.prevScene();
    }
  }
  nextScene() {
    const { player, changeSceneWithArrowsEnabled } = this.props;
    if (player && changeSceneWithArrowsEnabled) {
      player.nextScene();
    }
  }
  toggleSpaceplansKey() {
    this.setState({ spaceplansKey: !this.state.spaceplansKey })
  }
  startVRMode() {
    this.setState({ viewingVRModalOpen: false })
    this.props.player.toggleVRMode();
  }
  isVRAviable() {
    if (!this.state.vrChecked) {
      this.props.player && this.props.player.isVRCompatible().then(() => {
        if (this.detectMobile()) {
          this.setState({ vrAviable: false });
        } else {
          this.setState({ vrAviable: true });
          this.setState({ viewingVRModalOpen: true });
        }
      }, () => {
        this.setState({ vrAviable: false });
      });
      this.setState({ vrChecked: true });
    }
  }
  render() {
    const {
      t,
      classes,
      presentation,
      metatags = {},
      children,
      controlsWhite,
      minimal = false,
      vrButtonVisible,
      autoplayButtonVisible = true,
      fsButtonVisible = true,
      spaceplanButtonVisible = true,
      changeSceneWithPinEnabled,
    } = this.props,
    {
      sceneName,
      contentOpened,
      VRModalOpened,
      fullscreenActive,
      spaceplansOpened,
      currentSpaceplan,
      viewingVRModalOpen,
      zoom,
      isGyroscopeEnabled,
      isiOSDevice,
      vrAviable
    } = this.state,
    className = Classnames({
      "presentation-dashboard": true,
      "presentation-dashboard--minimal": minimal,
      "presentation-dashboard--fullscreen": fullscreenActive,
    });
    let title = `${(presentation && presentation.name) || ''}${sceneName?' | ' + sceneName : ''}`,
      titleImage = null,
      shareUrl = null,
      menuButtonVisible = false,
      autoplayVisible = false,
      presentationAccess;

    if (metatags) {
      presentationAccess = metatags.access;
      if (metatags.logotype && metatags.logotype.hash) {
        titleImage = AssetHelper.imagePath(metatags.logotype.hash, ImageVariants.THUMBNAIL);
      } else if (metatags.owner && metatags.owner.avatar) {
        titleImage = AssetHelper.imagePath(metatags.owner.avatar.hash, ImageVariants.THUMBNAIL);
      }

      shareUrl = `${location.protocol}//${location.host}/p/${metatags.hash}`;
    }

    if (presentation && ((presentation.spaceplans &&
      presentation.spaceplans.length ) || (
      presentation.menuScene &&
      presentation.menuScene.sceneId
    ))) {
      menuButtonVisible = spaceplanButtonVisible;
    }

    if (presentation && presentation.scenes
        && presentation.scenes.length > 1
        && (window.location.href.indexOf('/v/') < 0)
        && (window.location.href.indexOf('/vh/') < 0)) {
      autoplayVisible = true;
    }

    const fillWhite = fullscreenActive || controlsWhite;

    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const iPad = /iPad|iPod/.test(navigator.userAgent) && !window.MSStream;

    return (
      <div className={className}>
        <div
          className={
            "presentation-dashboard__wrapper"
            + (contentOpened ? ' presentation-dashboard__wrapper--open':'')
          }
        >
          <div className="presentation-dashboard__header">
            <div className="presentation-dashboard__title" onClick={this.toggleContent}>
              <div className="title-image-container">
                {titleImage && <img src={titleImage} />}
                {titleImage && <span className={`title__separator ${fillWhite && 'white'}`} />}
              </div>
              <div className="title-text-container">
                <h1 className={`title__text ${fillWhite && 'white'}`}>{title}</h1>
              </div>
            </div>
            <div className={"presentation-dashboard__controls"}>
              <div className="flex-container">
                <div className="slider-container">
                  <IconRemove
                    className={`slider-icons ${fillWhite && 'white'}`}
                    onClick={() => {
                      if (zoom > 1) {
                        this.zoomOut();
                        this.setState({
                          ...this.state,
                          zoom: zoom - 1,
                        });
                      }
                    }}
                  />
                    <Slider
                      min={1}
                      max={16}
                      defaultValue={zoom}
                      value={zoom}
                      className={`slider ${fillWhite && 'white'}`}
                      onChange={value => {
                        this.setState({
                          ...this.state,
                          zoom: value,
                        });
                        this.changeZoom(value);
                      }}
                    />
                  <IconAdd
                    className={`slider-icons ${fillWhite && 'white'}`}
                    onClick={() => {
                      if (zoom < 16) {
                        this.zoomIn();
                        this.setState({
                          ...this.state,
                          zoom: zoom + 1,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="controls-container">
                {autoplayButtonVisible && (
                  <React.Fragment>
                    {this.state.autoplay ? (
                      <IconPause className={`imageIcon ${fillWhite && 'white'}`} onClick={this.toggleAutoplay} />
                    ) : (
                      <IconAutoplay className={`imageIcon ${fillWhite && 'white'}`} onClick={this.toggleAutoplay} />
                    )}
                  </React.Fragment>
                )}
                {vrButtonVisible &&
                presentationAccess === 'PUBLIC' ||
                presentationAccess === 'PROTECTED' ||
                presentationAccess === undefined
                  ? (
                    <IconVr className={`imageIcon ${fillWhite && 'white'}`} onClick={this.toggleVR} />
                  ) : (
                    <Tooltip classes={classes} title={t('presentationOverlay.tooltip')} placement='top'>
                      <IconVr className={`imageIcon ${fillWhite && 'white'}`} />
                    </Tooltip>
                  )}
                {fsButtonVisible && (!iOS || iPad) && (
                  <IconFullscreen className={`imageIcon ${fillWhite && 'white'}`} onClick={this.toggleFullscreen} />
                )}
                <IconInfo className={`imageIcon ${fillWhite && 'white'} ${fullscreenActive && 'disabled'}`} onClick={this.toggleContent} />
                {minimal && children && <div className="controls-centered">
                  <IconArrow onClick={this.prevScene} className="controls__scenes-nav-arrow" />
                  <IconArrow onClick={this.toggleContent} className="controls__scenes-nav-arrow" />
                  <IconArrow onClick={this.nextScene} className="controls__scenes-nav-arrow" />
                </div>}
                {menuButtonVisible && presentation && presentation.spaceplans && (
                  <IconLayers className={`imageIcon ${fillWhite && 'white'}`} onClick={this.toggleSpaceplansModal} />
                )}
                {/* isiOSDevice var used to fix player enable gyroscope on mobile Safari */}
                {((isGyroscopeEnabled !== null) || isiOSDevice) && !vrAviable && (
                  <React.Fragment>
                    {isGyroscopeEnabled
                      ? (
                        <IconGyroscope className={`imageIcon ${fillWhite && 'white'}`} onClick={this.disableGyroscope} />
                      ) : (
                        <IconGyroscopeOff className={`imageIcon ${fillWhite && 'white'}`} onClick={this.enableGyroscope} />
                      )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
          {contentOpened && (
            <React.Fragment>
              {children && <div className="presentation-dashboard__content">
                { children }
              </div>}
            </React.Fragment>
          )}
        </div>
        {(viewingVRModalOpen && !this.state.coviewing) ? <StartCoViewingVRModal
          onClose={() => this.setState({ viewingVRModalOpen: false })}
          startVrMode={this.startVRMode}
          startInBrowser={() => this.setState({ viewingVRModalOpen: false })}
        /> : null}
        {spaceplansOpened &&
          <SpaceplansModal
            key={this.state.spaceplansKey}
            refreshComponent={() => {
              this.toggleSpaceplansKey();
            }}
            player={this.props.player}
            toggleSpaceplansModal={this.toggleSpaceplansModal}
            currentSpaceplan={currentSpaceplan}
            setPlanIndex={this.setPlanIndex}
            changeSceneWithPinEnabled={changeSceneWithPinEnabled}
            presentation={presentation}
          />}
        <VRModal opened={VRModalOpened} requestClose={this.toggleQRModal} shareUrl={shareUrl} />
      </div>
    );
  }
}

export default compose(
  translate("translations"),
  withStyles(styles),
)(WithDependency(PresentationOverlay));
